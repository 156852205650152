import axios, { type AxiosInstance } from 'axios';
import { get } from 'lodash';

import useUser from '@/lib/useUser';
import { JWTService } from '@/services/jwt';

const apiURL = process.env.NEXT_PUBLIC_API_URL;
const auth0ApiURL = process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL;
const axiosInstance: AxiosInstance = axios.create({ baseURL: apiURL });
export const axiosAuth0Instance: AxiosInstance = axios.create({ baseURL: auth0ApiURL });

axiosInstance.interceptors.request.use(
  async request => {
    const storedUser: string | null = localStorage.getItem('user');
    const anonymous = get(request, 'anonymous', false);

    if (request.headers) {
      request.headers['access-control-allow-origin'] = '*';
      request.headers.Tenant = 'supplier';
    }
    if (!anonymous && storedUser && request.headers) {
      const user: User = JSON.parse(storedUser || '');
      request.headers.Authorization = `Bearer ${user.accessToken}`;
    }

    return request;
  },
  async err => {
    return await Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response) {
      const isConfirmLocation = window.location.pathname.split('/')[1] === 'confirm';
      if (
        !isConfirmLocation &&
        error.response &&
        (error.response.status === 401 || error.response.status === 403 || !JWTService.isValidToken())
      ) {
        const { fetchUser } = useUser();
        localStorage.clear();
        fetchUser('/api/logout', 'POST');
      }
    }

    return await Promise.reject(error.response.data);
  },
);

axiosAuth0Instance.interceptors.request.use(
  async request => {
    const storedMngmtToken: string | null = localStorage.getItem('mngmtAccessToken');
    if (storedMngmtToken && request.headers) {
      const accessToken: string = JSON.parse(storedMngmtToken || '');
      request.headers.authorization = `Bearer ${accessToken}`;
      request.headers['content-type'] = 'application/json';
    }
    return request;
  },
  async err => {
    return await Promise.reject(err);
  },
);

axiosAuth0Instance.interceptors.response.use(
  response => response,
  async error => {
    const isConfirmLocation = window.location.pathname.split('/')[1] === 'confirm';
    if (!isConfirmLocation && error.response && error.response.data.statusCode === 401) {
      const { fetchUser } = useUser();
      localStorage.clear();
      fetchUser('/api/logout', 'POST');
    }

    return await Promise.reject(error.response.data);
  },
);

export default axiosInstance;
