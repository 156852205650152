import {
  type UserActions,
  UserActionTypes,
  type SetUser,
  type SetUserToken,
  type SetUserShowOnboarding,
} from '@/state/user/actions';
import { type UserState } from '@/state/user/state';

export const userReducer = (state: UserState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionTypes.SetUser:
      return { ...state, user: action.payload };
    case UserActionTypes.SetUserToken:
      return {
        ...state,
        user: { ...state.user, accessToken: action.payload.accessToken },
      };
    case UserActionTypes.SetUserShowOnboarding:
      return {
        ...state,
        user: { ...state.user, showOnboarding: action.payload.showOnboarding },
      };
    default:
      return state;
  }
};

export const getUserToken = (state: UserState): string | null => state.user.accessToken;

export const setUserToken = (accessToken: string): SetUserToken => ({
  type: UserActionTypes.SetUserToken,
  payload: { accessToken },
});

export const getUser = (state: UserState): User => state.user;

export const setUser = (user: User): SetUser => ({
  type: UserActionTypes.SetUser,
  payload: user,
});

export const getUserShowOnboarding = (state: UserState): boolean => state.user.showOnboarding;

export const setUserShowOnboarding = (showOnboarding: boolean): SetUserShowOnboarding => ({
  type: UserActionTypes.SetUserShowOnboarding,
  payload: { showOnboarding },
});
