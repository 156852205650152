/* eslint-disable import/no-anonymous-default-export */
export default {
  AD_BROADCASTS: '/ad-broadcasts',
  AUTH0_OAUTH_TOKEN: '/oauth/token',
  AUTH0_PASSWORDLESS_START: '/passwordless/start',
  AUTH0_ROLES: 'api/v2/roles',
  AUTH0_UPDATE_USER_METADATA: '/api/v2/users/',
  AUTH0_UPDATE_USERS: '/api/v2/users',
  BD_USER_PROFILE: '/users/profile',
  FAVORITE_PRODUCTS: '/favorite-products/',
  FILE_UPLOADS: '/file-uploads',
  ORDER_BULK_CONFIRM: '/orders/bulk-confirm',
  ORDER_EXPORT: '/orders/export',
  ORDER_GET: '/orders/',
  ORDER_UPDATE_TOKEN: '/orders/approve_anonymously',
  ORDERS: '/orders',
  PRODUCT_CATEGORIES: '/product-categories',
  PRODUCT_TO_FAVORITES: '/favorite-products/',
  PRODUCT_UNITS: '/products-measurement-units',
  PRODUCTS_BULK: '/products/bulk-update',
  PRODUCTS_DELETE_BULK: '/products/bulk-remove',
  PRODUCTS: '/products/',
  ADD_PRODUCT_CATALOG: '/products/add-catalog/',
  RESTAURANT_FAVORITES: '/products',
  RESTAURANT_GET: '/restaurants/',
  RESTAURANT_REGISTRATION: '/registrations/restaurants',
  RESTAURANT_SEARCH_AUTOCOMPLETE: '/searches/restaurants',
  RESTAURANT_SUPPLIER: '/restaurants-suppliers/',
  RESTAURANT_USER_INVITATIONS_DETAIL: '/supplier-user-invitations/details',
  RESTAURANT_USER_INVITATIONS: '/restaurant-invitations',
  SUPPLIER_CATALOG: '/products/',
  SUPPLIER_CATEGORIES: '/supplier-categories',
  SUPPLIER_GET: '/suppliers/',
  SUPPLIER_INVITE: '/supplier-invites',
  SUPPLIER_REGISTRATION: '/registrations/suppliers',
  SUPPLIER_REQUEST_ACCESS: '/access-requests/supplier',
  SUPPLIER_RESTAURANTS_BULK: '/restaurants-suppliers/bulk-update',
  SUPPLIER_RESTAURANTS: '/restaurants-suppliers',
  SUPPLIER_SEARCH_AUTOCOMPLETE: '/searches/suppliers',
  SUPPLIER_USER_INVITATION_POST: '/supplier-user-invitations',
  SUPPLIER_USER_INVITATIONS_DETAIL: '/supplier-user-invitations/details',
  SUPPLIER_USER_INVITATIONS: '/supplier-user-invitations',
  SUPPLIER_USER_VALIDATION: '/user-validations/supplier',
  UPLOAD_CSV: '/product-uploads/upload',
  UPLOAD_FILE: '/file-uploads',
  UPLOAD_FILES: '/file-uploads/multiple',
  PRODUCT_THUMBNAIL: '/file-uploads/product',
  USER_UPDATE_DATA: '/users/',
  SUPPLIER_USERS: '/supplier-users',
};
